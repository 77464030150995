.demo {
	background-color: rgb(40, 82, 131);
	height: 527px;
	position: relative;

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		background-image: url("../../images/border-1.webp");
		width: 100%;
		height: 14px;
		background-position: center bottom;
		background-size: auto 14px;
		z-index: 1;
	}

	.demo-anker {
		position: absolute;
		top: -93px;
	}

	.container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		max-width: none;
		height: 100%;
		padding: 0;
	}

	.demo-title {
		background-image: url("../../images/texture-3.png");
		background-size: 60px 75px;
		background-repeat: no-repeat;
		background-position: center;
		color: white;
		padding: 21px 0px;
		font-size: 34px;
		font-weight: 400;
		text-transform: uppercase;
	}

	.demo-block {
		margin: 0px auto;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 392px;
		max-width: 392px;
		height: 165px;
		position: relative;

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			width: 100%;
			height: 48px;
			background-image: url("../../images/figure-big-1.png");
			background-repeat: no-repeat;
			background-size: 100% 48px;
		}

		&::after {
			content: "";
			display: block;
			position: absolute;
			bottom: 0px;
			left: 0px;
			right: 0px;
			width: 100%;
			height: 48px;
			background-image: url("../../images/figure-big-2.png");
			background-repeat: no-repeat;
			background-size: 100% 48px;
		}
	}

	.demo-box-title {
		position: absolute;
		left: 0;
		right: 0;
		top: 104px;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		overflow: hidden;
	}

	.demo-slider {
		position: relative;
		height: 100%;

		.react-multi-carousel-track {
			height: 100%;
		}

		.react-multi-carousel-item {
			.demo-cloud {
				width: 100%;
				height: 100%;
				background-image: url("../../images/cloud-1.webp");
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				scale: 3;
			}
		}
	}

	.play-btn {
		margin-top: 0px;
	}

	@media screen and (max-width: 1600px) {
		.demo-slider {
			.react-multi-carousel-item {
				.demo-cloud {
					scale: 4;
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.demo-slider {
			.react-multi-carousel-item {
				.demo-cloud {
					scale: 5;
				}
			}
		}
	}

	@media screen and (max-width: 960px) {
		.demo-slider {
			.react-multi-carousel-item {
				.demo-cloud {
					scale: 6;
				}
			}
		}
	}

	@media screen and (max-width: 740px) {
		.demo-slider {
			.react-multi-carousel-item {
				.demo-cloud {
					scale: 7;
				}
			}
		}
	}

	@media screen and (max-width: 640px) {
		.demo-slider {
			.react-multi-carousel-item {
				.demo-cloud {
					scale: 8;
				}
			}
		}
	}

	@media screen and (max-width: 540px) {
		.demo-slider {
			.react-multi-carousel-item {
				.demo-cloud {
					scale: 10;
				}
			}
		}
	}

	@media screen and (max-width: 440px) {
		.demo-slider {
			.react-multi-carousel-item {
				.demo-cloud {
					scale: 12;
				}
			}
		}
	}

	@media screen and (max-width: 400px) {
		.demo-block {
			width: 370px;
		}
	}
}
