.popup-substrate {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	display: none;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.28);
	transition: 0.3s;

	&.show {
		display: block;
	}
}

.popup-register-now {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -150vh);
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: 914px;
	width: 90%;
	min-height: 559px;
	margin: 0px auto;
	padding: 80px 0;
	background-image: url("../../images/features-bg.png");
	transition: transform 0.3s;
	opacity: 0;

	&.show {
		opacity: 1;
		transform: translate(-50%, -50%);
	}

	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 0px;
		width: 100%;
		height: 15px;
	}

	&::before {
		top: -6px;
		background-image: url("../../images/border-4.webp");
		background-position: center bottom;
		background-size: auto 15px;
	}
	&::after {
		bottom: -6px;
		background-image: url("../../images/border-1.webp");
		background-position: center top;
		background-size: auto 15px;
	}

	.play-btn-block-figure-borders {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;

		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0px;
			height: 118px;
			width: 93%;
			margin: 0px auto;
			right: 0px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		&::before {
			top: 13px;
			background-image: url("../../images/sl-1-top.png");
		}

		&::after {
			bottom: 23px;
			background-image: url("../../images/sl-1-bottom.png");
		}
	}

	.form-register-now-sending {
		p {
			font-family: Aleo, sans-serif;
			font-size: 32px;
			font-weight: 400;
			line-height: 38px;
			text-align: center;
		}
	}

	.form-register-now {
		position: relative;
		max-width: 435px;

		&-title {
			margin: 0 0 20px 0;
			padding: 0 24px;
			font-family: Aleo, sans-serif;
			font-size: 32px;
			font-weight: 400;
			line-height: 38px;
			text-align: center;
			text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3333);
		}

		&-field {
			display: block;
			width: 240px;
			height: 48px;
			margin: 0 auto 20px;
			border: 1px solid #aeb4c2;
			border-radius: 8px;
			padding: 0 16px;
			font-family: "Open Sans", sans-serif;
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
		}

		.button-custom.register-now {
			margin: 0 auto 20px;
		}

		&-message {
			padding: 0 24px;
			font-family: Tajawal, sans-serif;
			font-size: 16px;
			font-weight: 400;
			line-height: 16px;
		}
	}
}
