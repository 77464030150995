.item-game {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 60px 0;

	.game-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 25px 40px 25px;

		&-logo {
			max-width: 480px;
			max-height: 248px;
			margin: 0 0 40px 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		&-description {
			margin: 0 0 23px 0;
			color: #ffffff;
			font-family: Aleo, sans-serif;
			font-size: 34px;
			font-weight: 400;
			line-height: 41px;
			text-align: center;
			text-transform: uppercase;
		}
	}

	.game-pic {
		width: 730px;
		height: 400px;
		margin: 0 25px;
		border-radius: 8px;
		border: 3px solid #ffc86d;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&.reverse {
		flex-direction: row-reverse;
	}
}

@media screen and (max-width: 576px) {
	.item-game {
		position: relative;
		padding: 60px 0 120px 0;
		margin: 0 0 40px 0;
		background-repeat: no-repeat;

		.game-info {
			&-logo {
				width: 295px;
			}

			&-description {
				margin: 0;
				font-size: 28px;
				line-height: 28px;
			}

			.play-btn,
			.coming-soon,
			.play-on-pc {
				position: absolute;
				bottom: 0;
				pointer-events: none;
			}

			.play-on-pc {
				background-image: url("../../../images/bg-buttons/bg-red-click.svg");
				color: #ffc86d;
			}
		}

		.game-pic {
			width: 380px;
			height: 210px;
		}
	}
}
