.play-btn {
	// margin-top: 29px;
	margin-top: 14px;

	.btn {
		position: relative;
		background-image: url("../../images/bt-red-simple.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 324px;
		height: 80px;

		background-color: transparent;
		border: none;
		outline: none;

		&::before {
			content: "";
			display: block;
			background-image: url("../../images/bt-red-simple-hover.png");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 100%;
			height: 100%;
			opacity: 0;
			left: 0;
			position: absolute;
			top: 0;
			transition: opacity 0.3s;
		}

		&::after {
			content: "Play Demo";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 28px;
			color: #ffc86d;
			text-transform: uppercase;
			transition: font-size 0.3s;
			transform: translateY(2px);
			font-weight: 400;
		}

		&:hover {
			&::before {
				opacity: 1;
			}
		}

		&:active {
			&::before {
				background-image: url("../../images/bt-red-simple-click.png");
				opacity: 1;
			}
			// filter: invert(13%) sepia(63%) saturate(589%) hue-rotate(321deg)
			// 	brightness(43%) contrast(98%);
		}
	}
}

.play-btn-wrap {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0px;
	left: 0px;
	z-index: 9999;
	background-color: rgb(0 0 0 / 28%);
	transform: translateX(-150vw);
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	& > div {
		width: 100%;
		max-width: 914px;
		margin: 0px 15px;
		transform: translateY(-100vh);
		transition: transform 0.5s ease-in-out;
	}

	&.active {
		transform: translateX(0vw);
		opacity: 1;
		& > div {
			transform: translateY(0vh);
		}
	}

	.play-btn-block {
		position: relative;
		width: 100%;
		// max-width: 914px;
		height: 477px;
		background-image: url("../../images/features-bg.png");
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		margin: 0px auto;

		&-figure-borders {
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;

			&::before,
			&::after {
				content: "";
				position: absolute;
				left: 0px;
				height: 118px;
				width: 93%;
				margin: 0px auto;
				right: 0px;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}

			&::before {
				top: 13px;
				background-image: url("../../images/sl-1-top.png");
			}
			&::after {
				bottom: 23px;
				background-image: url("../../images/sl-1-bottom.png");
			}
		}

		&-title {
			font-size: 32px;
			color: black;
			text-transform: uppercase;
			margin-top: 65px;
			margin-bottom: 29px;
			text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3333);
			z-index: 100;

			@media screen and (max-width: 700px) {
				margin-top: 71px;
			}
		}

		&-bt-1,
		&-bt-2 {
			position: relative;
			padding: 6px 18px;
			font-size: 0;
			border-radius: 5px;
			text-decoration: none;
			z-index: 100;
			background-image: url("../../images/bt-blue-simple.png");
			background-position: center;
			background-size: 100% 100%;
			padding: 26px 10px;
			width: 480px;

			&::before,
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background-position: center;
				background-size: 100% 100%;
				font-family: "Aleo";
				font-weight: 400;
				font-size: 28px;
				color: #ffc86d;
			}

			&::before {
				content: "Play in browser";
				text-decoration: none;
				text-transform: uppercase;
				position: relative;
				z-index: 100;
			}

			&::after {
				opacity: 0;
				transition: opacity 0.3s;
			}

			&:hover {
				&::after {
					background-image: url("../../images/bt-blue-simple-hover.png");
					opacity: 1;
				}
			}

			&:active {
				&::after {
					background-image: url("../../images/bt-blue-simple-click.png");
				}
			}
		}

		&-bt-2 {
			&::before {
				content: "Play on Elixir";
			}
		}

		&-or {
			font-size: 30px;
			font-family: "Aleo";
			line-height: 100%;
			margin: 23px 0px;
			color: black;
			font-weight: 600;
			text-transform: uppercase;
			z-index: 100;
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0px;
			width: 100%;
			height: 15px;
		}

		&::before {
			top: -6px;
			background-image: url("../../images/border-4.webp");
			background-position: center bottom;
			background-size: auto 15px;
		}
		&::after {
			bottom: -6px;
			background-image: url("../../images/border-1.webp");
			background-position: center top;
			background-size: auto 15px;
		}
	}
}

.header-demo {
	cursor: pointer;
	background-image: url("../../images/subtract.png");
	background-size: 100% 100%;
	position: absolute;
	top: -21px;
	right: 78px;
	width: 124px;
	height: 158px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: top 0.7s;

	&-text {
		line-height: 28px;
		font-family: "Aleo";
		font-weight: 400;
		text-align: center;
		position: relative;
		font-size: 28px;
		color: #ffe8c2;
		top: 10px;
		text-transform: uppercase;
		transition: top 0.7s;
	}

	&:hover {
		top: -5px;

		// .header-demo-text {
		// 	top: 0px;
		// }
	}
}

@media screen and (max-width: 960px) {
	.header-demo {
		right: 25px;
	}
	.play-btn-wrap {
		.play-btn-block {
			height: auto;
			padding-bottom: 70px;
			padding-left: 5px;
			padding-right: 5px;
		}
		.play-btn-block-title {
			font-size: 22px;
			margin-top: 50px;
		}
		.play-btn-block-figure-borders::before,
		.play-btn-block-figure-borders::after {
			height: 77px;
		}

		.play-btn-block-bt-1,
		.play-btn-block-bt-2 {
			padding: 15px 5px;
			width: 333px;
		}

		.play-btn-block-bt-1::before,
		.play-btn-block-bt-2::before {
			font-size: 21px;
		}

		.play-btn-block-or {
			font-size: 25px;
			margin: 13px 0px;
		}
	}
}

@media screen and (max-width: 840px) {
	.header-demo {
		display: none;
	}
}
