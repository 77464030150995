.link-custom {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 340px;
	height: 86px;
	border: 0;
	padding: 0;
	color: #ffc86d;
	background-color: transparent;
	background-size: cover;
	background-position: center;
	font-family: Aleo, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	transition: opacity 0.3s ease-in-out;

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-position: center;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		z-index: 1;
	}

	&:hover {
		text-decoration: none;
		color: #ffc86d;
	}

	&:active {
		color: #000000;
		transition: opacity 0.3s ease-in-out;
		&::before,
		&::after {
			transition: opacity 0.3s ease-in-out;
		}
	}

	&:focus {
		outline: none;
	}

	&.coming-soon {
		background-image: url("../../../images/bg-buttons/bg-red-click.svg");
		pointer-events: none;
		color: #ffc86d !important;
	}

	&.sign-up {
		background-image: url("../../../images/bg-buttons/bg-red.svg");

		&::before {
			background-image: url("../../../images/bg-buttons/bg-red-hover.svg");
		}

		&::after {
			background-image: url("../../../images/bg-buttons/bg-red-click.svg");
		}

		&:hover {
			&::before {
				opacity: 1;
			}
		}

		&:active {
			&::after {
				opacity: 1;
			}
		}
	}

	&.register-now {
		background-image: url("../../../images/bg-buttons/bg-red.svg");

		&::before {
			background-image: url("../../../images/bg-buttons/bg-red-hover.svg");
		}

		&::after {
			background-image: url("../../../images/bg-buttons/bg-red-click.svg");
		}

		&:hover {
			&::before {
				opacity: 1;
			}
		}

		&:active {
			&::after {
				opacity: 1;
			}
		}
	}

	&.play-in-browser {
		background-image: url("../../../images/bg-buttons/bg-blue.svg");

		&::before {
			background-image: url("../../../images/bg-buttons/bg-blue-hover.svg");
		}

		&::after {
			background-image: url("../../../images/bg-buttons/bg-blue-click.svg");
		}

		&:hover {
			&::before {
				opacity: 1;
			}
		}

		&:active {
			&::after {
				opacity: 1;
			}
		}
	}

	&.play-on-elixir {
		background-image: url("../../../images/bg-buttons/bg-blue.svg");

		&::before {
			background-image: url("../../../images/bg-buttons/bg-blue-hover.svg");
		}

		&::after {
			background-image: url("../../../images/bg-buttons/bg-blue-click.svg");
		}

		&:hover {
			&::before {
				opacity: 1;
			}
		}

		&:active {
			&::after {
				opacity: 1;
			}
		}
	}

	&.play-demo {
		background-image: url("../../../images/bg-buttons/bg-red.svg");

		&::before {
			background-image: url("../../../images/bg-buttons/bg-red-hover.svg");
		}

		&::after {
			background-image: url("../../../images/bg-buttons/bg-red-click.svg");
		}

		&:hover {
			&::before {
				opacity: 1;
			}
		}

		&:active {
			&::after {
				opacity: 1;
			}
		}
	}

	&.view-all {
		background-image: url("../../../images/bg-buttons/bg-blue.svg");

		&::before {
			background-image: url("../../../images/bg-buttons/bg-blue-hover.svg");
		}

		&::after {
			background-image: url("../../../images/bg-buttons/bg-blue-click.svg");
		}

		&:hover {
			&::before {
				opacity: 1;
			}
		}

		&:active {
			&::after {
				opacity: 1;
			}
		}
	}
	span {
		position: relative;
		z-index: 2;
		display: inline-flex;
		padding-top: 5px;
		letter-spacing: -0.9px;
		transition: color 0.3s ease-in-out;
	}
}
