.race {
	position: relative;
	color: white;
	background-color: black;
	background-image: url("../../images/race-bg.svg");
	background-size: cover;
	background-position: center;
	text-align: center;
	height: 565px;

	.story-anker {
		position: absolute;
		top: -93px;
	}

	.container {
		max-width: none;
	}

	&-title {
		font-size: 42px;
		text-shadow: 0px 0px 10px rgba(255, 153, 0, 0.78);
		font-weight: 400;
		text-transform: uppercase;
		padding: 54px 0px 0px;
		margin-bottom: 4px;
		color: rgba(255, 168, 0, 1);
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: -18px;
		width: 100%;
		height: 62px;
		background-image: url("../../images/border-2.webp");
		background-position: center 0px;
		background-size: auto 100%;
	}

	.race-mobile {
		display: none;
	}

	.react-multi-carousel-list {
		max-width: 1500px;
		margin: 0 auto;
	}

	.race-slide {
		// background-color: green;
		&-img {
			height: 300px;
			background-position: bottom;
			background-repeat: no-repeat;
			background-size: contain;

			// @media screen and (max-width: 600px) {
			// 	height: 190px;
			// 	background-size: contain;
			// }
		}
		&-1 {
			.race-slide-img {
				background-image: url("../../images/race-1.png");
			}
		}
		&-2 {
			.race-slide-img {
				background-image: url("../../images/race-2.png");
			}
		}
		&-3 {
			.race-slide-img {
				background-image: url("../../images/race-3.png");
			}
		}
		&-4 {
			.race-slide-img {
				background-image: url("../../images/race-4.png");
			}
		}
		&-5 {
			.race-slide-img {
				background-image: url("../../images/race-5.png");
			}
		}
		&-6 {
			.race-slide-img {
				background-image: url("../../images/race-6.png");
				background-position: 25% bottom;
			}
		}
		&-title {
			font-family: "Aleo";
			font-size: 28px;
			text-transform: uppercase;
			line-height: 100%;
		}
	}

	.race-desctop {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		justify-content: center;
		max-width: 1473px;
		margin: 0 auto;

		.race-slide-img {
			height: 311px;
			margin: 0 -13px;
		}

		.race-slide-1 .race-slide-img {
			width: 216px;
			background-position: center -28px;
		}
		.race-slide-2 .race-slide-img {
			width: 283px;
			background-size: 196%;
			background-position: center -71px;
		}
		.race-slide-3 .race-slide-img {
			width: 300px;
			background-size: 106%;
			background-position: center -44px;
		}
		.race-slide-4 .race-slide-img {
			width: 238px;
			background-size: 220%;
			background-position: center -51px;
		}
		.race-slide-5 .race-slide-img {
			width: 273px;
			background-size: 201%;
			background-position: center -71px;
		}
		.race-slide-6 .race-slide-img {
			width: 290px;
			background-size: 130%;
			background-position: center 54px;
		}
	}

	.race-border {
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		background-image: url("../../images/features-bg.png");

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: -46px;
			width: 100%;
			height: 48px;
			background-image: url("../../images/border-3.webp");
			background-position: center;
			background-size: auto 100%;
		}
	}

	@media screen and (max-width: 1530px) {
		.race-desctop {
			scale: 91%;
		}
	}

	@media screen and (max-width: 1400px) {
		// .race-slide-img {
		// 	height: 250px;
		// }
		// .race-slide-title {
		// 	font-size: 22px;
		// }
		.race-desctop {
			.race-slide-title {
				font-size: 28px;
			}
		}
	}

	@media screen and (max-width: 1340px) {
		.race-desctop {
			scale: 76%;
		}
	}

	@media screen and (max-width: 1100px) {
		.race-desctop {
			scale: 68%;
		}
	}

	@media screen and (max-width: 1000px) {
		.race-desctop {
			scale: 61%;
			position: relative;
			top: -10px;
		}
	}

	@media screen and (max-width: 900px) {
		.race-desctop {
			scale: 50%;
		}
	}

	@media screen and (max-width: 960px) {
		.race-title {
			margin-bottom: 5vw;
		}
		.race-desctop {
			top: -6vw;
		}
	}

	@media screen and (max-width: 740px) {
		.race-desctop {
			display: none;
		}
		.race-mobile {
			display: block;
		}

		.race-title {
			font-size: 32px;
			padding-left: 15px;
			padding-right: 15px;
		}

		.react-multi-carousel-list {
			scale: 1;
			top: 0px;
		}

		.race-slide-1 .race-slide-img {
			background-size: 200px 284px;
			background-position: center 9px;
		}
		.race-slide-2 .race-slide-img {
			background-size: 518px 366px;
			background-position: center -38px;
		}
		.race-slide-3 .race-slide-img {
			background-size: 316px 301px;
			background-position: center -8px;
		}
		.race-slide-4 .race-slide-img {
			background-size: 522px 400px;
			background-position: center -59px;
		}
		.race-slide-5 .race-slide-img {
			background-size: 509px 367px;
			background-position: center -39px;
		}
		.race-slide-6 .race-slide-img {
			background-size: 341px 252px;
			background-position: center 69px;
		}
	}

	@media screen and (max-width: 600px) {
		// height: 550px;

		.race-slide-title {
			font-size: 20px;
		}

		.container {
			padding-left: 0px;
			padding-right: 0px;
		}
	}

	@media screen and (max-width: 491px) {
		.race-mobile {
			position: relative;
			top: -14px;
		}
		.race-slide-img {
			height: 248px;
		}
		.race-slide-1 .race-slide-img {
			background-size: 163px 233px;
			background-position: center 6px;
		}
		.race-slide-2 .race-slide-img {
			background-size: 447px 304px;
			background-position: center -38px;
		}
		.race-slide-3 .race-slide-img {
			background-size: 240px 222px;
			background-position: center 9px;
		}
		.race-slide-4 .race-slide-img {
			background-size: 438px 329px;
			background-position: center -55px;
		}
		.race-slide-5 .race-slide-img {
			background-size: 455px 308px;
			background-position: center -39px;
		}
		.race-slide-6 .race-slide-img {
			background-size: 288px 213px;
			background-position: center 51px;
		}
	}
}
