.endless {
	position: relative;
	background-image: url("../../images/bg-endles.webp");
	background-size: cover;
	padding-bottom: 48px;
	z-index: 1;

	&-title {
		margin: 0 0 116px 0;
		padding: 165px 0 0 0;
		color: rgba(255, 168, 0, 1);
		font-size: 42px;
		font-weight: 400;
		text-shadow: 0px 0px 10px rgba(255, 153, 0, 0.78);
		text-transform: uppercase;
		text-align: center;
	}

	.list-game {
		width: 100%;
		margin: 0;
		padding: 0;
	}

	&::after {
		content: "";
		display: block;
		position: absolute;
		bottom: 0;
		background-image: url("../../images/border-1.webp");
		width: 100%;
		height: 14px;
		background-position: center bottom;
		background-size: auto 14px;
		z-index: 1;
		transform: rotate(180deg);
	}
}

@media screen and (max-width: 576px) {
	.endless {
		&-title {
			margin: 0;
			padding: 170px 0 0 0;
			font-size: 20px;
			line-height: 24px;
		}
	}
}
