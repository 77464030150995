.greetings {
	position: relative;
	background-image: url("../../images/gr-3.avif");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	// &::after {
	// 	content: '';
	// 	display: block;
	// 	position: absolute;
	// 	bottom: 0;
	// 	background-image: url('../../images/bg-line.png');
	// 	width: 100%;
	// 	height: 14px;
	// 	background-position: center bottom;
	// 	background-size: auto 14px;
	// 	z-index: 1;
	// }

	.greetings-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: calc(95px + 38px) 0 0 0;

		&-logo {
			max-width: 630px;
			margin: 0 0 38px 0;

			img {
				width: 100%;
				max-width: 630px;
			}
		}

		&-message {
			margin: 0 0 38px 0;
			font-family: Aleo, sans-serif;
			font-size: 34px;
			font-weight: 400;
			line-height: 41px;
			text-align: center;
			text-transform: uppercase;
			text-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
		}
	}
}

@media screen and (max-width: 520px) {
	.greetings {
		background-image: url("../../images/gr-4.gif");

		.greetings-box {
			padding: 70px 0 0 0;

			&-logo {
				margin: 0 0 14px 0;
			}

			&-message {
				margin: 0 0 14px 0;
				font-size: 28px;
			}
		}
	}
}
