.button-bookmark {
	position: relative;
	top: 0px;
	width: 170px;
	height: 220px;
	padding-top: 24px;
	padding-right: 11px;
	border: 0;
	color: #ffe8c2;
	background-color: transparent;
	background-image: url("../../../images/bg-flag.png");
	background-position: center;
	transition: 0.3s;
	background-size: cover;

	font-family: Aleo, sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	text-align: center;

	&:focus {
		outline: none;
	}

	&:hover {
		top: 20px;
	}
}
