html {
	scroll-behavior: smooth;
}

body {
	font-family: 'Aleo';
	font-weight: 400;
}

.App {
	overflow: hidden;
}

h1 {
	font-size: 32px;
	font-family: 'Aleo';
	color: black;
	text-align: center;
}

p {
	font-family: 'Tajawal';
	font-size: 19px;
}

.container {
	max-width: 1460px;
	margin: 0px auto;
	padding: 10px 15px;
}
