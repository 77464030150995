.header {
	background: #001b33e0;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	height: 95px;
	position: fixed;
	width: 100vw;
	top: 0px;
	z-index: 10;

	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		max-width: 1955px;
		height: 100%;
		padding: 0;
	}

	.header-logo {
		position: absolute;
		top: -4px;
		left: 22px;
		width: 220px;
	}

	.header-nav {
		margin-left: 48px;
		padding: 0 0 0 220px;

		ul {
			display: flex;
			list-style: none;
			padding-left: 0px;
			margin: 0px;

			li {
				padding: 0px 15px;
				border-left: 4px solid;
				border-image: linear-gradient(
						0deg,
						rgba(255, 255, 255, 0),
						rgba(255, 255, 255, 0.5),
						rgba(255, 255, 255, 0)
					)
					1;
			}
		}

		a {
			font-size: 24px;
			text-transform: uppercase;
			color: white;
			text-decoration: none;
			transition: opacity 0.3s;

			&:hover {
				opacity: 0.8;
				transition: opacity 0.3s;
			}
		}
	}

	.button-bookmark {
		margin: 0 22px 0 0;
	}

	.header-nav-mobile,
	.header-nav-mobile-btn {
		display: none;
	}

	@media screen and (max-width: 1200px) {
		.header-logo {
			top: 16px;
			width: 120px;
		}

		.header-nav {
			padding: 0 0 0 120px;

			a {
				font-size: 17px;
			}
		}
	}

	@media screen and (max-width: 800px) {
		position: fixed;
		width: 100vw;
		height: 70px;
		background: linear-gradient(
			180deg,
			rgba(0, 27, 51, 0.79) 0%,
			rgba(0, 27, 51, 0.79) 85%,
			rgba(0, 27, 51, 0) 100%
		);

		.container {
			height: 60px;
			padding: 0;
			justify-content: space-between;
			transform: translateY(-2px);
		}

		.header-nav,
		.button-bookmark {
			display: none;
		}

		.header-logo {
			position: relative;
			top: 0;
			width: 120px;
			margin: 0;
		}

		.header-nav-mobile {
			display: flex;
			align-items: center;
			flex-direction: column;
			position: absolute;
			left: 0;
			right: 0;
			top: 64px;
			background-color: transparent;
			padding-top: 57px;
			min-height: calc(150vh - 72px);
			min-height: -webkit-fill-available;
			transform: translate(-100vw, 0px);
			opacity: 0;
			transition: opacity 0.3s ease-in-out;
			background-image: url("../../images/cloud-1.webp");
			background-repeat: no-repeat;
			background-position: 6% -69px;

			.header-nav-mobile-logo {
				width: 376px;
				margin-bottom: 25px;
			}

			.header-nav-mobile-demo-link {
				display: none;
				font-size: 28px;
				text-transform: uppercase;
				// font-family: 'Tajawal';
				text-decoration: none;
				color: white;
				background-color: #4700dd;
				max-width: 283px;
				text-align: center;
				border-radius: 5px;
				line-height: 100%;
				padding: 9px 5px;
				margin-top: 10px;
				transition: all 0.3s;

				&:hover {
					background-color: rgba(83, 30, 233, 1);
				}
			}

			.header-nav-mobile-title {
				font-family: "Aleo";
				font-size: 50px;
				text-transform: uppercase;
				color: white;
				text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3333);
				line-height: 100%;
				margin-top: 58px;
			}

			.header-nav-mobile-icons {
				display: flex;
				justify-content: center;
				margin-top: 37px;

				.header-nav-mobile-icon {
					flex: 1 0 60px;
					height: 58px;
					margin: 0px 16px;
					background-position: center;
					background-repeat: no-repeat;
					position: relative;
					color: transparent;

					&::after {
						content: "";
						position: absolute;
						top: 26px;
						right: -18px;
						width: 4px;
						height: 4px;
						border-radius: 50%;
						background-color: white;
					}

					&-1 {
						background-image: url("../../images/twitter.svg");
					}

					&-2 {
						background-image: url("../../images/discord.svg");
					}

					&-3 {
						background-image: url("../../images/medium.svg");
						background-size: contain;
					}

					&-4 {
						background-image: url("../../images/youtube.svg");

						&::after {
							display: none;
						}
					}
				}
			}

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 15px;
				background-image: url("../../images/border-8.png");
				background-position: center;
				background-repeat: no-repeat;
			}
		}

		.header-nav-mobile-btn {
			display: block;
			position: relative;
			width: 33px;
			height: 33px;
			margin-right: 28px;
			outline: none;
			border: none;

			background-image: url("../../images/open.png");
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-color: transparent;
		}

		&.mobile-open {
			height: 100vh;
			position: fixed;
			width: 100%;
			background: rgb(46, 78, 126);
			background: linear-gradient(
				0deg,
				rgba(46, 78, 126, 1) 0%,
				rgba(29, 43, 64, 1) 100%
			);

			.header-nav-mobile {
				transform: translate(0vw, 0px);
				opacity: 1;
			}

			.header-nav-mobile-btn {
				background-image: url("../../images/close.png");
			}
		}
	}
}
