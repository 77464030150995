.features {
	position: relative;
	background-image: url("../../images/features-bg.png");
	scroll-behavior: smooth;
	text-align: center;
	padding-bottom: 123px;

	.features-anker {
		position: absolute;
		top: -93px;
	}

	.features-title {
		font-size: 34px;
		font-weight: 400;
		text-transform: uppercase;
		background-image: url("../../images/texture-3.png");
		background-repeat: no-repeat;
		background-position: center;
		height: 140px;
		margin: 50px 0 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.features-bottom-texture {
		width: 336px;
		height: 47px;
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: -6px;
		width: 100%;
		height: 14px;
		background-image: url("../../images/border-4.webp");
		background-position: center bottom;
		background-size: auto 14px;
		z-index: 1;
	}

	&::after {
		content: "";
		display: block;
		position: absolute;
		bottom: 58px;
		width: 100%;
		height: 50px;
		background-image: url("../../images/texture-2.png");
		background-position: center;
		background-repeat: no-repeat;
	}

	.features-slider-wrap {
		position: relative;
		max-width: 910px;
		height: 416px;
		margin: 30px auto 0px;

		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0px;
			width: 100%;
			height: 100px;
			background-repeat: no-repeat;
			background-position: center;
		}
		&::before {
			top: 0;
			background-image: url("../../images/sl-1-top.png");
		}
		&::after {
			bottom: 0;
			background-image: url("../../images/sl-1-bottom.png");
		}

		.features-slider {
			height: 356px;
			// padding-top: 18px;

			// .react-multi-carousel-item {
			// 	padding: 0px 60px;
			// }

			.react-multiple-carousel__arrow--left,
			.react-multiple-carousel__arrow--right {
				outline: none;
				min-width: 50px;
				min-height: 56px;
				background-position: center;
				background-repeat: no-repeat;
				background-color: transparent;
				transform: translateY(18px);

				&::before {
					display: none;
				}
			}

			.react-multiple-carousel__arrow--left {
				left: 3px;
				background-image: url("../../images/sl-1-arrow-left.png");
			}
			.react-multiple-carousel__arrow--right {
				right: 3px;
				background-image: url("../../images/sl-1-arrow-right.png");
			}

			.react-multi-carousel-dot-list {
				display: flex;
				align-items: center;
				z-index: 100;

				.react-multi-carousel-dot button {
					width: 16px;
					height: 16px;
					background-color: #ffe6ca;
					border-color: black;
				}
				.react-multi-carousel-dot--active button {
					width: 24px;
					height: 24px;
					background-color: black;
				}
			}
		}

		.features-slid {
			display: flex;
			transform: translateY(21px);
			padding: 0px 130px 0px 130px;

			.react-multi-carousel-item {
				padding: 0px 100px !important;
			}

			&-img {
				width: 250px;
				height: 220px;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;

				&-1 {
					background-image: url("../../images/sl-1-icon-1.png");
				}
				&-2 {
					background-image: url("../../images/sl-1-icon-2.png");
				}
				&-3 {
					background-image: url("../../images/sl-1-icon-3.png");
				}
				&-4 {
					background-image: url("../../images/sl-1-icon-4.png");
				}
				&-5 {
					background-image: url("../../images/sl-1-icon-5.png");
				}
			}
			&-wrap {
				flex: 1;
			}
			&-title {
				text-transform: uppercase;
				font-weight: 400;
				font-size: 26px;
			}
			&-text {
				font-size: 19px;
				line-height: 22px;
				padding: 40px 26px 0px;
			}
		}
	}

	@media screen and (max-width: 960px) {
		.features-slider-wrap {
			height: 303px;

			&::before,
			&::after {
				background-size: contain;
				background-position: top;
			}

			&::after {
				background-position: bottom;
			}

			.features-slider {
				height: 256px;
				top: 10px;
			}

			.features-slid-img {
				width: 150px;
				height: 120px;
			}

			.features-slid-title {
				font-size: 22px;
			}

			.features-slid-text {
				font-size: 16px;
				line-height: 18px;
				padding: 5px 15px 0px;
			}
		}
	}

	@media screen and (max-width: 740px) {
		.features-title {
			margin: 14px 0 0 0;
			height: 60px;
			background-size: contain;
		}

		.features-slider-wrap {
			height: 370px;
			margin: 17px auto 0px;

			&::before,
			&::after {
				height: 50px;
				background-size: 100%;
				background-position: top;
			}

			&::after {
				background-position: bottom;
			}

			.features-slider {
				height: 326px;

				.react-multi-carousel-track {
					height: 100%;
				}
			}

			.features-slider .react-multiple-carousel__arrow--left,
			.features-slider .react-multiple-carousel__arrow--right {
				min-width: 41px;
				min-height: 61px;
				top: 36px;
			}

			.features-slider .react-multiple-carousel__arrow--left {
				left: 46px;
			}

			.features-slider .react-multiple-carousel__arrow--right {
				right: 46px;
			}

			.features-slid {
				position: relative;
				top: -13px;
				height: 100%;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				padding: 0px 48px;
				align-items: center;

				.features-slid-wrap {
					flex: none;
				}

				.features-slid-title {
					margin-top: 15px;
				}
			}

			.features-slid-img {
				width: 90px;
				height: 100px;
			}
		}
	}

	@media screen and (max-width: 429px) {
		.features-slider-wrap {
			.features-slid {
				padding: 0px 20px;
			}
			.features-slid-title {
				font-size: 17px;
				margin-bottom: 0px;
			}
		}
	}
}
