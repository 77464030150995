.story {
	background-image: url('../../images/features-bg.png');
	padding-bottom: 113px;
	position: relative;
	padding-bottom: 113px;

	.story-anker {
		position: absolute;
		top: -93px;
	}

	.container {
		max-width: 1480px;
	}

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		bottom: 28px;
		width: 100%;
		height: 54px;
		background-image: url('../../images/texture-1.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: auto 28px;
	}

	&::before {
		bottom: auto;
		top: 52px;
	}

	.story-title {
		padding: 51px 0px;
		margin: 111px 0px 40px;
		font-size: 34px;
		font-weight: 400;
		text-transform: uppercase;
		background-image: url('../../images/texture-3.png');
		background-repeat: no-repeat;
		background-position: center;
	}

	.story-description {
		font-family: 'Tajawal';
		font-size: 24px;
		text-align: center;
		max-width: 945px;
		margin: 0px auto 60px;
		line-height: 30px;
	}

	.story-box {
		position: relative;

		img {
			max-width: 100%;
		}
	}

	.story-2 {
		margin-top: -76px;
		right: -31px;

		.story-box-title {
			left: auto;
			top: 38%;
			right: 10%;
			text-align: right;
			color: #212c39;
			border-bottom: 5px solid #212c39;
		}
	}
	.story-3 {
		// background-image: url('../../images/story-3.png');
		// margin-top: -40px;
		margin-top: -65px;

		.story-box-title {
			top: 40%;
		}
	}

	.story-box-title {
		position: absolute;
		color: white;
		left: 8.5%;
		top: 35%;
		font-family: 'Aleo';
		font-weight: 600;
		font-size: 40px;
		line-height: 47px;
		border-bottom: 5px solid white;
		padding-bottom: 40px;
		width: 286px;
	}

	@media screen and (max-width: 1500px) {
		.story-2 {
			margin-top: -5vw;
			right: -2vw;
		}
		.story-3 {
			margin-top: -4.3vw;
		}
	}

	@media screen and (max-width: 960px) {
		.story-box-title {
			font-size: 4vw;
			line-height: 4.5vw;
			padding-bottom: 4vw;
		}
	}

	@media screen and (max-width: 740px) {
		.story-box-title {
			font-size: 25px;
		}
		.story-description {
			margin: 0px auto 30px;
		}
	}

	@media screen and (max-width: 480px) {
		.story-box-title {
			width: 142px;
		}

		.story-box {
			margin-top: 0;
			margin-bottom: -15px;
			height: 214px;
			overflow: hidden;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				height: 100%;
				max-width: initial;
			}

			.story-box-title {
				font-size: 19px;
				line-height: 21px;
			}
		}

		.story-2 {
			.story-box-title {
				right: 15px;
			}
		}
	}
}
