@font-face {
	font-family: 'Aleo';
	src: url('../../public/assets/fonts/Aleo-Bold.ttf') format('truetype');
	src: url('../../public/assets/fonts/Aleo-Bold.otf') format('opentype');
	src: url('../../public/assets/fonts/Aleo-Bold.woff') format('woff');
	font-weight: 600;
}

@font-face {
	font-family: 'Aleo';
	src: url('../../public/assets/fonts/Aleo-SemiBold.ttf') format('truetype');
	src: url('../../public/assets/fonts/Aleo-SemiBold.otf') format('opentype');
	src: url('../../public/assets/fonts/Aleo-SemiBold.woff') format('woff');
	font-weight: 500;
}

@font-face {
	font-family: 'Aleo';
	src: url('../../public/assets/fonts/Aleo-Regular.ttf') format('truetype');
	src: url('../../public/assets/fonts/Aleo-Regular.otf') format('opentype');
	src: url('../../public/assets/fonts/Aleo-Regular.woff') format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Aleo';
	src: url('../../public/assets/fonts/Aleo-Light.ttf') format('truetype');
	src: url('../../public/assets/fonts/Aleo-Light.otf') format('opentype');
	src: url('../../public/assets/fonts/Aleo-Light.woff') format('woff');
	font-weight: 300;
}

@font-face {
	font-family: 'BaiJamjuree';
	src: url('../../public/assets/fonts/BaiJamjuree-Bold.ttf') format('truetype');
	src: url('../../public/assets/fonts/BaiJamjuree-Bold.otf') format('opentype');
	src: url('../../public/assets/fonts/BaiJamjuree-Bold.woff') format('woff');
	font-weight: 600;
}

@font-face {
	font-family: 'Tajawal';
	src: url('../../public/assets/fonts/Tajawal-Regular.ttf') format('truetype');
	src: url('../../public/assets/fonts/Tajawal-Regular.otf') format('opentype');
	src: url('../../public/assets/fonts/Tajawal-Regular.woff') format('woff');
	font-weight: 400;
}
