.popup-substrate {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	display: none;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	transition: 0.3s;
	backdrop-filter: blur(5px);

	&.show {
		display: block;
	}
}

.popup-subscribing {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -150vh);
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: 914px;
	width: 90%;
	min-height: 559px;
	margin: 0px auto;
	padding: 80px 0;
	background-image: url("../../images/features-bg.png");
	transition: transform 0.3s;
	opacity: 0;

	&.show {
		opacity: 1;
		transform: translate(-50%, -50%);
	}

	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 0px;
		width: 100%;
		height: 15px;
	}

	&::before {
		top: -6px;
		background-image: url("../../images/border-4.webp");
		background-position: center bottom;
		background-size: auto 15px;
	}
	&::after {
		bottom: -6px;
		background-image: url("../../images/border-1.webp");
		background-position: center top;
		background-size: auto 15px;
	}

	.play-btn-block-figure-borders {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;

		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0px;
			height: 118px;
			width: 93%;
			margin: 0px auto;
			right: 0px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		&::before {
			top: 13px;
			background-image: url("../../images/sl-1-top.png");
		}

		&::after {
			bottom: 23px;
			background-image: url("../../images/sl-1-bottom.png");
		}
	}

	&-logo {
		width: 382px;
		margin: 0 0 40px 0;

		img {
			width: 100%;
		}
	}

	&-title {
		padding: 0 20px;
		font-family: Aleo, sans-serif;
		font-size: 32px;
		font-weight: 400;
		line-height: 38px;
		text-align: center;
	}

	&-message {
		max-width: 476px;
		width: 100%;
		padding: 0 20px;
		font-family: Tajawal, sans-serif;
		font-size: 22px;
		font-weight: 400;
		line-height: 26px;
		text-align: center;
	}
}
