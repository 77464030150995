.screenshots {
	background-color: rgb(249, 230, 220);
	padding: 0px 15px;
	box-shadow: 0 0px 13px 2px rgba(115, 115, 115, 0.8);

	.screenshots-desctop {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 1407px;
		gap: 2%;
		padding: 37px 0px 2px;
	}

	&-scr {
		position: relative;
		width: 32%;
		min-width: 338px;
		margin-bottom: 2%;
		border-radius: 5px;
		// background-position: center;
		// background-size: 102% 102%;
		box-shadow: 0px 0px 0px rgb(106 106 106 / 58%);
		transition: all 0.5s;
		overflow: hidden;
		cursor: pointer;

		img {
			width: 100%;
			scale: 1.01;
			transition: transform 0.5s;
		}

		&:hover {
			box-shadow: -1px 3px 10px rgba(74, 74, 74, 0.28);
			img {
				transform: scale(1.02, 1.02);
			}
		}

		@media only screen and (max-width: 1445px) {
			// height: 200px;
		}
	}

	.screenshots-sl {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.28);
		z-index: 99999;

		& > div {
			max-width: 1200px;
			// height: 654px;
			margin: 0 auto;
			top: calc(50vh - 347px);
			position: relative;
		}

		.screenshots-slider {
			// max-width: 1200px;
			// height: 654px;
			// margin: 0 auto;
			// top: calc(50vh - 347px);

			.react-multiple-carousel__arrow {
				width: 30px;
				height: 150px;
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				background-color: transparent;
				border: none;
				outline: none;
			}

			.react-multiple-carousel__arrow--left {
				left: 0px;
				background-image: url('../../images/white-arrow-left.png');
			}

			.react-multiple-carousel__arrow--right {
				right: 0px;
				background-image: url('../../images/white-arrow-right.png');
			}

			.react-multi-carousel-item {
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					max-width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: 960px) {
		.container {
			justify-content: center;
		}
	}

	@media screen and (max-width: 740px) {
		.screenshots-scr {
			width: 90%;
		}
	}
}
